
import { Options, Vue } from 'vue-class-component'

@Options({
	computed: {
		backgroundUrl() {
			return this.$store.state.styles.background.url
		}
	}
})

export default class App extends Vue {
	isInMaintenance = false
	appPaddingTop = "4rem"

	beforeMount() : void {
		if(localStorage.getItem("token"))
			this.$store.commit('SetToken', localStorage.getItem("token"))
		else
			this.$router.push("/login")
	}

	mounted() : void {
		if(this.isInMaintenance)
			this.$router.push("/maintenance");

		if(document.body.classList.contains("init-fadein"))
		{
			if(!document.body.classList.contains("fadein"))
				window.setTimeout(() => { document.body.classList.add("fadein"); }, 200);
			else
			{
				document.body.classList.remove("fadein");
				window.setTimeout(() => { document.body.classList.add("fadein"); }, 1400);
			}
		}
	}

	OnHeaderExit(e: MouseEvent) : boolean {
		e?.preventDefault()

		this.$store.commit('SetAppHeaderFullscreen', false)
		this.$store.commit('SetAppHeaderNavigation', true)
		this.$store.commit('SetAppOpened', true)

		return false
	}

	static _(id: string) : HTMLElement | null { return document.getElementById(id); }
}
