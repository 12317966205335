
import { Options, Vue } from "vue-class-component"

import { AxiosResponse } from "axios"
import axios from "axios"

import ValidateToken from "@/components/ValidateToken.vue"

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'

import { faArrowLeft, faTrash } from '@fortawesome/free-solid-svg-icons'
library.add(faArrowLeft, faTrash);

class DeviceInfos {
	static instance = 0

	uniqid: string = '' + (DeviceInfos.instance++)
	
	name = ''
	model = ''
	identifier = ''

	registration = '-'
	last_activity = '-'
}

class DeviceCollection extends Array<DeviceInfos> {}

class DeviceCollectionAPIResponse {
	result = 0
	content?: {
		auth: { token: string }
		devices: DeviceCollection,
		error: { 
			code: string,
			message: string
		}
	}
}

@Options({
	components: {
		FontAwesomeIcon,
		ValidateToken,
	},

	computed: {
		UUID() {
			return this.$route.params.uuid
		}
	}
})

export default class Devices extends Vue {
	apiError : string[] = []

	devices: DeviceCollection = []
	resultDevices: DeviceCollection = []

	researchFieldValue = ""
	loginUri = '/login'
	dashboardUri = '/dashboard'

	OnValidateHandler(success: boolean, msg = ''): void {
		if(!success)
		{
			this.apiError.push(msg)
			this.$router.push(this.loginUri)
		}
	}

	mounted() : void {
		this.GetDeviceList()
	}

	GoBack(e: MouseEvent) : boolean {
		e.preventDefault()

		this.$router.push(this.dashboardUri)

		return false
	}

	GetDeviceList() : void {
		const uri = this.$store.state.api.url + '/users/' + this.$route.params.uuid + '/devices'
		
		const options = {
			headers: {
				'Authorization': this.$store.state.api.auth.token
			}
		}
		
		axios.get(uri, options).then((response: AxiosResponse) => {
			const apiResponse = response.data as DeviceCollectionAPIResponse

			if(!apiResponse.content)
				this.apiError.push('Une réponse du serveur est attendue mais aucune réponse n\'est parvenue')
			else if(apiResponse.content.error)
				this.apiError.push(apiResponse.content.error.message)
			else
				this.devices = apiResponse.content.devices
		}).catch((reason) => {
			this.apiError.push(reason.response.data.content.error.message)
		})
	}

	ResearchDevice(e: KeyboardEvent) : boolean
	{
		if(e.key === 'Enter')
		{
			this.resultDevices = []
			
			this.devices.forEach(e => {
				if(this.researchFieldValue.includes(e.name))
					this.resultDevices.push(e)
				else if(this.researchFieldValue.includes(e.model))
					this.resultDevices.push(e)
				else if(this.researchFieldValue.includes(e.identifier))
					this.resultDevices.push(e)
				else if(this.researchFieldValue.includes(e.last_activity))
					this.resultDevices.push(e)
				else if(this.researchFieldValue.includes(e.uniqid))
					this.resultDevices.push(e)
			})
		}

		return false
	}

	DeleteDevice(e: MouseEvent, uniqid: string) : boolean {
		e.preventDefault()

		const uri = this.$store.state.api.url + '/users/' + this.$route.params.uuid + '/devices/' + uniqid
		
		const options = {
			headers: {
				'Authorization': this.$store.state.api.auth.token
			}
		}
		
		axios.delete(uri, options).then((response: AxiosResponse) => {
			const apiResponse = response.data as DeviceCollectionAPIResponse

			if(apiResponse.result === 1)
			{
				if(!apiResponse.content)
					this.GetDeviceList()
				else
					this.apiError.push("Le serveur a envoyé une réponse qui n'était pas attendue: --unknown-success")
			}
			else if(apiResponse.content && apiResponse.content.error)
				this.apiError.push(apiResponse.content.error.message)
			else
				this.apiError.push("Le serveur a envoyé une erreur qui n'était pas attendue: --unknown-error")

		}).catch((reason) => {
			this.apiError.push(reason.response.data.content.error.message)
		})

		return false
	}
}
