import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

import Login from "@/views/Login.vue";

import Dashboard from "@/views/Dashboard.vue";
import Devices from "@/views/Devices.vue";

const routes: Array<RouteRecordRaw> = [
	{
		path: "/",
		name: "Login",
		component: Login,
	},
	{
		path: "/dashboard",
		name: "Dashboard",
		component: Dashboard,
	},
	{
		path: "/dashboard/:uuid/devices",
		name: "Devices",
		component: Devices,
	},
	{
		path: "/login",
		redirect: "/",
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

export default router;
