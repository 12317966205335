
import { Options, Vue } from "vue-class-component";

import { AxiosResponse } from "axios"
import axios from "axios"

export class ValidateAPIResponse {
	result = 0
	content = {
		auth: { token: '' },
		error: { 
			code: '',
			message: ''
		}
	}
}

@Options({})
export default class ValidateToken extends Vue {
	beforeMount(): void {
		const token = localStorage.getItem('token')

		if(token)
			this.Validate(token)
	}

	Validate(token: string): void
	{
		const uri = this.$store.state.api.url + '/auth/refresh'
		const options = {
			headers: { 'Authorization': 'Bearer <' + token + '>' }
		}

		axios.post(uri, {}, options)
		.then((response: AxiosResponse) => {
			const apiResponse = response.data as ValidateAPIResponse

			if(apiResponse.content.error)
			{
				this.$emit('OnValidateEvent', false, apiResponse.content.error.message)
				return
			}

			this.$emit('OnValidateEvent', true)
		})
		.catch((error) => {
			const apiResponse = error.response.data as ValidateAPIResponse
			this.$emit('OnValidateEvent', false, apiResponse.content.error.message)
		})
	}
}
